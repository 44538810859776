// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/H1sSqOztj";

const cycleOrder = ["eIArR_UEx", "qdS0DbrWm"];

const serializationHash = "framer-Q7rri"

const variantClassNames = {eIArR_UEx: "framer-v-ejniib", qdS0DbrWm: "framer-v-1kmpilg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "eIArR_UEx", Mobile: "qdS0DbrWm"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, KXOZ068vp: title ?? props.KXOZ068vp ?? "Strategic Planning", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eIArR_UEx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KXOZ068vp, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eIArR_UEx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ejniib", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eIArR_UEx"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} {...addPropertyOverrides({qdS0DbrWm: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-a4xuu5"} data-styles-preset={"H1sSqOztj"}>Strategic Planning</motion.p></React.Fragment>} className={"framer-1m865ku"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"kqTzUNSFa"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={KXOZ068vp} variants={{qdS0DbrWm: {"--extracted-r6o4lv": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({qdS0DbrWm: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBSZWd1bGFy", "--framer-font-family": "\"Brandon Text Regular\", \"Brandon Text Regular Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "1.5em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64)))"}}>Strategic Planning</motion.p></React.Fragment>, fonts: ["CUSTOM;Brandon Text Regular"]}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Q7rri.framer-h4031k, .framer-Q7rri .framer-h4031k { display: block; }", ".framer-Q7rri.framer-ejniib { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 4px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Q7rri .framer-1m865ku { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Q7rri.framer-ejniib { gap: 0px; } .framer-Q7rri.framer-ejniib > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Q7rri.framer-ejniib > :first-child { margin-left: 0px; } .framer-Q7rri.framer-ejniib > :last-child { margin-right: 0px; } }", ".framer-Q7rri.framer-v-1kmpilg.framer-ejniib { width: 118px; }", ".framer-Q7rri.framer-v-1kmpilg .framer-1m865ku { flex: 1 0 0px; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"qdS0DbrWm":{"layout":["fixed","auto"]}}}
 * @framerVariables {"KXOZ068vp":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdJ1n6Z1wG: React.ComponentType<Props> = withCSS(Component, css, "framer-Q7rri") as typeof Component;
export default FramerdJ1n6Z1wG;

FramerdJ1n6Z1wG.displayName = "strat cycle text";

FramerdJ1n6Z1wG.defaultProps = {height: 36, width: 150};

addPropertyControls(FramerdJ1n6Z1wG, {variant: {options: ["eIArR_UEx", "qdS0DbrWm"], optionTitles: ["Variant 1", "Mobile"], title: "Variant", type: ControlType.Enum}, KXOZ068vp: {defaultValue: "Strategic Planning", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerdJ1n6Z1wG, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}, {family: "Brandon Text Regular", source: "custom", url: "https://framerusercontent.com/assets/UiqYoOgOidN7cJ2idK1gh0xVw5I.otf"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})